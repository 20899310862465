<template>
  <div v-show="isBillingAddressFormVisible" class="content">
    <legend class="mt-2 header-xsmall">2. {{ $t('text.account.paymentDetails.billingAddress') }}</legend>
    <form-group
      v-if="!isRestrictedToRetailer"
      type="checkbox"
      :multi-items="sameAsDeliveryCheckbox"
      :label-classes="'text-sm'"
      name="sameAsDeliveryAddress"
      show-label
      :required="false"
      qaid="billing-address-checkbox"
      @change="handleCheckboxChange"
    />
    <checkout-personal-information :phone-max-length="phoneMaxLength" />
    <billing-address
      :postcode-format="postcodeFormat"
      :postcode-max-length="postcodeMaxLength"
      :is-postcode-lookup-enabled="isPostcodeLookupEnabled"
      :regions="regions"
    >
      <template v-if="isPostcodeLookupEnabled" #postcode-autofill>
        <slot name="postcode-autofill" />
      </template>
    </billing-address>
  </div>
</template>

<script>
import CheckoutPersonalInformation from '@/components/checkout/forms/CheckoutPersonalInformation';
import BillingAddress from '@/components/checkout/forms/billing/BillingAddress.vue';
import FormGroup from '@/components/FormGroup';
import {BillingAddressApi} from '@/api/checkout/billing-address';
import {mapState, mapGetters} from 'vuex';
import {PaymentProvider} from '@/constants/payment-provider';

const FIELD_KEY_MAP = {
  firstName: 'firstName',
  lastName: 'lastName',
  phone: 'phone',
  line1: 'line1',
  line2: 'line2',
  townCity: 'town',
  regionIso: 'regionIso',
  postcode: 'postalCode',
};

export default {
  components: {CheckoutPersonalInformation, BillingAddress, FormGroup},
  inject: ['validationErrors'],
  props: {
    postcodeFormat: {
      type: String,
      required: true,
    },
    postcodeMaxLength: {
      type: Number,
      required: true,
    },
    isPhoneRequired: {
      type: Boolean,
      default: true,
    },
    isFormInvalid: {
      type: String,
      default: '',
    },
    isPostcodeLookupEnabled: {
      type: Boolean,
      default: false,
    },
    addressValidationConfig: {
      type: Object,
      default: () => null,
    },
    phoneMaxLength: {
      type: Number,
      default: () => null,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    regions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSameAsDeliveryAddressChecked: !this.isRestrictedToRetailer,
    };
  },
  computed: {
    ...mapState('checkout', ['selectedPaymentProvider']),
    ...mapGetters('checkout', ['isRestrictedToRetailer']),

    sameAsDeliveryCheckbox() {
      return [
        {
          name: 'sameAsDeliveryAddress',
          label: 'same-as-delivery-address-checkbox',
          value: 'sameAsDeliveryAddr',
          displayName: this.$t('payment.billing.same.delivery.address'),
          checked: this.isSameAsDeliveryAddressChecked,
        },
      ];
    },
    isBillingAddressFormVisible() {
      return (
        this.selectedPaymentProvider?.value !== PaymentProvider.CYBERSOURCE_APPLE &&
        this.selectedPaymentProvider?.value !== PaymentProvider.GR4VY_APPLE
      );
    },
  },
  mounted() {
    this.getAndSetDeliveryAddress(true);
  },
  methods: {
    handleCheckboxChange(evt) {
      this.isSameAsDeliveryAddressChecked = evt.target.checked;
      this.getAndSetDeliveryAddress(this.isSameAsDeliveryAddressChecked);
    },
    async getAndSetDeliveryAddress(isSameAsDeliveryAddressChecked) {
      try {
        let addressInfo;
        if (isSameAsDeliveryAddressChecked) {
          let response = await BillingAddressApi.getSameAsDeliveryAddress();
          addressInfo = response?.data?.responseObject;
          addressInfo.regionIso = addressInfo?.region?.isocode;
        }
        for (const property in FIELD_KEY_MAP) {
          if (!document.getElementById(`${property}`)) continue;
          this.$set(
            document.getElementById(`${property}`),
            'value',
            isSameAsDeliveryAddressChecked ? addressInfo[FIELD_KEY_MAP[property]] : ''
          );
          document.getElementById(`${property}`).disabled = isSameAsDeliveryAddressChecked;
          document
            .getElementById(`${property}`)
            .dispatchEvent(new Event(property === 'regionIso' ? 'change' : 'input'));
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
