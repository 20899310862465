import {StockStatus} from '@/constants/stock-status';

//Configuration array for controlling in-stock/out-of-stock status on B2C products
const inStockCodesB2C = [
  StockStatus.IN_STOCK,
  StockStatus.IN_WAREHOUSE,
  StockStatus.BTO_IN_STOCK,
  StockStatus.BUILD_TO_ORDER,
];

//B2B has so many in-stock codes that it is less verbose to list out of stock codes here.
const outOfStockCodesB2B = [StockStatus.OUT_OF_STOCK];

/**
 * Check whether the status is in stock or not by given stock status code.
 * @param stockStatusCode
 * @return {boolean}
 */
const isInStockByCode = (stockStatusCode) => {
  return inStockCodesB2C.includes(stockStatusCode);
};

const isBtoCode = (stockStatusCode) => {
  return StockStatus.BUILD_TO_ORDER === stockStatusCode;
};

const isInStockByCodeB2B = (stockStatusCode) => {
  return !outOfStockCodesB2B.includes(stockStatusCode);
};

const isInStock = (b2b, stockStatusCode) => {
  return b2b ? isInStockByCodeB2B(stockStatusCode) : isInStockByCode(stockStatusCode);
};

/**
 * Check whether the status is in warehouse or not by given stock status code.
 * @param stockStatusCode
 * @return {boolean}
 */
const isInWarehouseByCode = (stockStatusCode) => {
  return stockStatusCode === StockStatus.IN_WAREHOUSE;
};

export {isInStockByCode, isInStock, isInWarehouseByCode, isBtoCode};
