<script>
import {mapState, mapGetters} from 'vuex';
import TrekButton from '@/components/TrekButton';
import TrekLink from '@/components/TrekLink';
import {PdlLoading} from '@pedal/pdl-loading';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlSectionHeader, PdlHeading} from '@pedal/pdl-section-header';
import {CheckoutMixin} from '@/components/checkout/mixins/checkout.js';

export default {
  name: 'CartSummary',
  components: {TrekButton, TrekLink, PdlLoading, PdlCollapse, PdlCollapseItem, PdlSectionHeader, PdlHeading},
  mixins: [CheckoutMixin],

  props: {
    cartSummary: {
      type: Object,
      default: () => ({
        bikes: {},
        parts: {},
        customWaterBottles: {},
        projectOneBikes: {},
        total: '',
      }),
    },
    tabSelected: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    locale: {
      type: String,
      default: '',
    },
    hasReviewButton: Boolean,
    isPlaceOrderEnabled: {
      type: Boolean,
      default: true,
    },
    expanded: Boolean,
    isTaxableEmployee: Boolean,
  },

  data() {
    return {
      activeTabs: [],
      isSubmitting: false,
      hasPlacedOrderUrlParam: false,
    };
  },

  computed: {
    ...mapState('cart', ['hasAllocatedItemsProcessing', 'hasCartValidationError']),
    ...mapState('miniCart', ['containers']),
    ...mapState('backend', ['isConsumerFriendlyMode']),
    ...mapGetters('checkout', ['isRestrictedToRetailer']),
    ...mapState('user', ['trekUCompetencyLevel']),

    disableSubmit() {
      return (
        !this.isPlaceOrderEnabled || this.isSubmitting || this.hasCartValidationError || this.hasPlacedOrderUrlParam
      );
    },

    bikesAreAvailable: {
      cache: false,
      get() {
        if (this.cartSummary?.bikes?.totalItems) {
          const totalItems = parseInt(this.cartSummary.bikes.totalItems);
          return !isNaN(totalItems) && totalItems > 0;
        }
        return false;
      },
    },

    partsAreAvailable: {
      cache: false,
      get() {
        if (this.cartSummary?.parts?.totalItems) {
          const totalItems = parseInt(this.cartSummary.parts.totalItems);
          return !isNaN(totalItems) && totalItems > 0;
        }
        return false;
      },
    },

    customWaterBottlesAreAvailable: {
      cache: false,
      get() {
        if (this.cartSummary?.customWaterBottles?.totalItems) {
          const totalItems = parseInt(this.cartSummary.customWaterBottles.totalItems);
          return !isNaN(totalItems) && totalItems > 0;
        }
        return false;
      },
    },

    projectOneBikesAreAvailable: {
      cache: false,
      get() {
        if (this.cartSummary?.projectOneBikes?.totalItems) {
          const totalItems = parseInt(this.cartSummary.projectOneBikes.totalItems);
          return !isNaN(totalItems) && totalItems > 0;
        }
        return false;
      },
    },

    cartIsEmpty() {
      return (
        !this.bikesAreAvailable &&
        !this.partsAreAvailable &&
        !this.customWaterBottlesAreAvailable &&
        !this.projectOneBikesAreAvailable
      );
    },

    showReviewButton() {
      return !this.cartIsEmpty && this.bikesAreAvailable && this.partsAreAvailable;
    },

    highlightPartsMinAmount() {
      if (
        this.cartSummary &&
        this.cartSummary.parts &&
        this.cartSummary.parts.totalPrice &&
        this.cartSummary.parts.vendorMinimumDollarAmount
      ) {
        // Highlight total price if it's less than the minimum order amount
        return (
          Number(this.cartSummary.parts.totalPrice.replace(/[^0-9.-]+/g, '')) <
          Number(this.cartSummary.parts.vendorMinimumDollarAmount.replace(/[^0-9.-]+/g, ''))
        );
      }

      return false;
    },

    highlightCustomWaterBottlesMinAmount() {
      if (
        this.cartSummary?.customWaterBottles?.totalPrice &&
        this.cartSummary?.customWaterBottles?.vendorMinimumDollarAmount
      ) {
        // Highlight total price if it's less than the minimum order amount
        return (
          Number(this.cartSummary.customWaterBottles.totalPrice.replace(/[^0-9.-]+/g, '')) <
          Number(this.cartSummary.customWaterBottles.vendorMinimumDollarAmount.replace(/[^0-9.-]+/g, ''))
        );
      }

      return false;
    },

    minDollarAmountPresent() {
      // When vendor min amount is set to null, backend sends a string amount of "0.0"
      // instead of null which causes the null logic checker on FE to be bypassed.
      // Therefore we need this new checker
      if (
        this.cartSummary &&
        this.cartSummary.parts &&
        this.cartSummary.parts.totalPrice &&
        this.cartSummary.parts.vendorMinimumDollarAmount &&
        this.cartSummary.parts.isVendorMinimumDollarAmountNull === false
      ) {
        return true;
      }
      return false;
    },

    minDollarAmountPresentCustomWaterBottles() {
      // When vendor min amount is set to null, backend sends a string amount of "0.0"
      // instead of null which causes the null logic checker on FE to be bypassed.
      // Therefore we need this new checker
      if (
        this.cartSummary.customWaterBottles?.totalPrice &&
        this.cartSummary.customWaterBottles?.vendorMinimumDollarAmount &&
        this.cartSummary.customWaterBottles?.isVendorMinimumDollarAmountNull === false
      ) {
        return true;
      }
      return false;
    },

    disableCheckoutButton() {
      return this.cartSummary != null && !this.cartSummary.isCheckoutAllowed && !this.isRestrictedToRetailer;
    },
  },

  mounted() {
    if (this.expanded) {
      this.activeTabs = [
        'cart-summary-bikes',
        'cart-summary-parts',
        'cart-summary-custom-water-bottles',
        'cart-summary-project-one',
      ];
    }

    this.checkForPlacedOrderUrlParam();
  },

  methods: {
    showTax(type) {
      return this.cartSummary[type]?.totalTax && (this.locale === 'zh_CN' || this.isTaxableEmployee);
    },
    placeOrderAction() {
      this.isSubmitting = true;
      this.$store.commit('storage/removeUserTouchedAllocatedItems');
    },
    activeTabsChange(tabs) {
      this.activeTabs = tabs;
    },

    checkoutDisabledCheck(event) {
      if (!this.isRestrictedToRetailer) return;

      if (!this.cartSummary?.isCheckoutAllowed) {
        event.preventDefault();
        this.showTrekUOfflineToastMessage();
      } else if (this.cartSummary?.isBikePurchaseLimitReached) {
        event.preventDefault();
        this.showBikeLimitReachedMessage();
      } else if (this.trekUCompetencyLevel === undefined) {
        event.preventDefault();
        this.showNoCompetencyLevelMessage();
      }
    },
    appendPlacedOrderUrlParam() {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('order-placed', 'true');
      const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
      history.pushState(null, '', newRelativePathQuery);
    },
    checkForPlacedOrderUrlParam() {
      const searchParams = new URLSearchParams(window.location.search);
      this.hasPlacedOrderUrlParam = searchParams.get('order-placed');
    },
  },
};
</script>
