import get from 'lodash/get';
import {Price} from '@/components/containers/pdp/utils/price';
import {PriceRange} from '@/components/containers/pdp/utils/price-range';

const fieldsMap = {
  price: 'value',
  advancedOrdering: 'advancedValue',
  advertised: 'value',
  saleAdvertised: 'value',
};

class ProductPriceData {
  /**
   * Get formatted price data.
   * @return {{oldPrice: string, advancedValue: string, value: string}}
   */
  get displayPrice() {
    return this.displayPriceValue;
  }

  /**
   * Check whether the sale price exists and it's not equal to old price.
   * @return {boolean}
   */
  get hasSalePrice() {
    const price = get(this.price, 'low.value', 0);
    return price > 0 && this.wasPrice.value !== price;
  }

  /**
   * Init object from JSON data.
   * @param data
   */
  initFromJson(data) {
    if (!data) {
      data = {};
    }

    this.excludeTaxes = get(data, 'excludeTaxes', false);
    this.bulkSalePrices = get(data, 'bulkSalePrices', false);
    this.bulkPrices = get(data, 'bulkPrices', false);
    this.wasPrice = new Price(data.wasPrice);
    this.savedAmount = new Price(data.savedAmount);

    ['price', 'apple', 'saleAdvertised', 'advertised', 'advancedOrdering'].forEach((range) => {
      this[range] = new PriceRange(get(data, `${range}.low`), get(data, `${range}.high`));
    });
  }

  /**
   * Initialize display price.
   */
  calculateDisplayPrice() {
    let price = {};
    let displayPrice = {value: '', oldPrice: '', advancedValue: '', isSale: false, calculable: ''};

    // Price range
    Object.keys(fieldsMap).forEach((priceType) => {
      if (!displayPrice[fieldsMap[priceType]].length && this[priceType].low.value) {
        price = this[priceType];
        displayPrice[fieldsMap[priceType]] = price.displayPrice;

        // String value without $
        displayPrice.calculable = displayPrice.value.substring(1);
      }
    });

    // Single price & old price
    if (!displayPrice.value) {
      price = this.price;
      displayPrice.value = price.formatted;
    }

    if (!('hasRange' in price) || !price.hasRange()) {
      if (
        get(this.wasPrice, 'value') > 0 &&
        this.wasPrice.formatted !== displayPrice.value &&
        get(price, 'low.value') !== this.wasPrice.value
      ) {
        displayPrice.oldPrice = this.wasPrice.formatted;
      }
    }

    return displayPrice;
  }

  constructor(data = null, options = {}) {
    this.options = Object.assign(
      {
        b2b: false,
      },
      options
    );

    this.initFromJson(data);
    this.displayPriceValue = this.calculateDisplayPrice();
  }
}

export {ProductPriceData};
