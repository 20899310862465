<template>
  <form-group
    v-model="lastName"
    :maxlength="19"
    :name="fieldName"
    :label="$t('address.surname')"
    required
    show-error-messages
    force-display-error
    :error="error ? error : getErrorMessage('lastName')"
    qaid="last-name-field"
  />
</template>

<script>
import FormGroup from '@/components/FormGroup';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';
import {mapState, mapMutations} from 'vuex';
export default {
  components: {FormGroup},
  mixins: [TrekValidationMixin],
  props: {
    error: {
      type: String,
      default: undefined,
    },
  },
  validations() {
    return {
      lastName: {
        required: this.trekValidators.requiredWithCustomMessage('address.lastName.invalid'),
        maxLength: this.trekValidators.maxLength('address.lastName', 19),
      },
    };
  },
  computed: {
    ...mapState('checkout', ['billingAddress']),

    fieldName() {
      return 'lastName';
    },
    lastName: {
      get() {
        return this.billingAddress.lastName;
      },
      set(lastName) {
        this.setLastName(lastName);
      },
    },
  },
  watch: {
    lastName(inputValue) {
      this.$emit('input', {field: `surnameValue`, value: inputValue});
    },
  },
  methods: {
    ...mapMutations('checkout', ['setLastName']),
  },
};
</script>
