import {FulfillmentOptionTypes} from '@/constants/fulfillment-options.js';
import {ProductApi} from '@/api/product.js';
import store from '@/store';
import {userActions} from '@/store/modules/user.js';

const FulfillmentOptionComponentMap = Object.freeze({
  [FulfillmentOptionTypes.SHIP_TO_HOME]: {
    code: FulfillmentOptionTypes.SHIP_TO_HOME,
    component: 'ShipToHome',
    name: 'fulfillment.shipToHome.heading',
  },
  [FulfillmentOptionTypes.PICK_UP_IN_STORE]: {
    code: FulfillmentOptionTypes.PICK_UP_IN_STORE,
    component: 'PickUpInStore',
    name: 'fulfillment.storePickup.heading',
  },
  [FulfillmentOptionTypes.DEALER_DELIVERY]: {
    code: FulfillmentOptionTypes.DEALER_DELIVERY,
    component: 'PremiumHomeDelivery',
    name: 'fulfillment.premium.heading',
  },
});

function convertToComponent(option) {
  const componentMapEntry = FulfillmentOptionComponentMap[option.code];
  return {
    ...option,
    component: componentMapEntry?.component,
  };
}

function handleECommDisabled(fulfillmentOptionList, isLocallyActive) {
  if (!isLocallyActive) return fulfillmentOptionList;
  fulfillmentOptionList.push(FulfillmentOptionComponentMap.PICK_UP_IN_STORE);
  return fulfillmentOptionList;
}

async function addPlaceholderOptions({fulfillmentOptionList, storeId, postalCode, latitude, longitude}) {
  if (postalCode && storeId) return;
  if (!storeId) {
    if (!latitude && !longitude) {
      fulfillmentOptionList.push(FulfillmentOptionComponentMap['PICK-UP-IN-STORE']);
    } else {
      await store.dispatch(userActions.LOAD_NEAREST_RETAILER);
      if (store.state.user.hasFetchRetailerError) {
        fulfillmentOptionList.push(FulfillmentOptionComponentMap['PICK-UP-IN-STORE']);
      }
    }
  }
  if (!postalCode) {
    fulfillmentOptionList.push(FulfillmentOptionComponentMap['SHIP-TO-HOME']);
  }
}

function fulfillmentOptionComparator(a, b) {
  const optionPriority = [
    FulfillmentOptionTypes.PICK_UP_IN_STORE,
    FulfillmentOptionTypes.SHIP_TO_HOME,
    FulfillmentOptionTypes.DEALER_DELIVERY,
  ];

  const firstIdx = optionPriority.indexOf(a.code);
  const secondIdx = optionPriority.indexOf(b.code);

  if (firstIdx < 0) return 1;
  if (secondIdx < 0) return -1;

  return firstIdx - secondIdx;
}

const ProductFulfillmentConfig = {
  getFulfillmentConfig: async function ({
    productCode,
    storeId,
    latitude,
    longitude,
    postalCode,
    regionCode,
    isECommAvailable,
    isLocallyActive,
    lang,
    curr,
  }) {
    let fulfillmentOptionList = [];

    if (!isECommAvailable) return handleECommDisabled(fulfillmentOptionList, isLocallyActive);

    addPlaceholderOptions({fulfillmentOptionList, storeId, postalCode, latitude, longitude});
    if (!storeId || !postalCode) return fulfillmentOptionList;

    try {
      const options = {
        storeId,
        postalCode,
        regionCode,
        lang,
        curr,
      };
      const {fulfillmentOptions} = await ProductApi.getProductFulfillmentOptions(productCode, options);
      fulfillmentOptionList = [
        ...fulfillmentOptions.map((option) => convertToComponent(option)),
        ...fulfillmentOptionList,
      ];
    } catch (error) {
      console.error(error);
    }
    return fulfillmentOptionList.sort(fulfillmentOptionComparator);
  },
};
export {ProductFulfillmentConfig, fulfillmentOptionComparator};
