'use strict';

/**
 * Initialize slider
 */

var DEFAULTS = {
  lazyLoad: true,
  nav: true,
  stepIndicator: true,
  dots: false,
  navText: ['Prev', 'Next'],
  navContainerClass: 'owl-nav owl-nav--float product-set__controls',
  navClass: ['owl-prev pagination__button arrow-left-icon', 'owl-next pagination__button arrow-right-icon'],
  indicatorCountClass: 'owl-count pagination__text text-small',
  indicatorSeparatorClass: 'owl-separator text-small-emphasized',
  indicatorTotalClass: 'owl-total pagination__text text-small',
  indicatorSeparatorText: window.ACC.indicatorSeparatorText,
  margin: 40,
};

var DICT = {
  hero: {
    items: 1,
    stepIndicator: false,
    dots: true,
    lazyLoad: true,
    loop: true,
    nav: false,
    navContainerClass: 'owl-nav owl-nav--center',
    navClass: ['owl-prev hero-pagination-button-left', 'owl-next hero-pagination-button-right'],
    responsive: {
      960: {
        nav: true,
      },
    },
  },
  'pdp-hero': {
    items: 1,
    stepIndicator: false,
    dots: true,
    lazyLoad: true,
    loop: false,
    nav: true,
    autoHeight: true,
    navContainerClass: 'owl-nav owl-nav--center',
    navClass: ['owl-prev pdp-hero-arrow-left', 'owl-next pdp-hero-arrow-right'],
    margin: 0,
  },
  'pdp-zoom': {
    items: 1,
    stepIndicator: false,
    dots: true,
    lazyLoad: true,
    loop: false,
    center: true,
    nav: true,
    navText: ['', ''],
    navContainerClass: 'owl-nav owl-nav--center',
    navClass: ['owl-prev zoom-pagination-button-left', 'owl-next zoom-pagination-button-right'],
    margin: 0,
  },
  'pdp-thumb': {
    stepIndicator: false,
    dots: false,
    lazyLoad: true,
    loop: false,
    nav: true,
    autoHeight: true,
    navContainerClass: 'owl-nav owl-nav--center',
    navClass: ['owl-prev hero-pagination-button-left', 'owl-next hero-pagination-button-right'],
    margin: 0,
    responsive: {
      0: {
        items: 5,
      },
      640: {
        items: 6,
      },
    },
  },
  fade: {
    items: 1,
    stepIndicator: false,
    dots: false,
    lazyLoad: true,
    loop: true,
    nav: false,
    animateOut: 'fadeOut',
    autoplay: true,
    autoplayTimeout: 5000,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
  },
  still: {
    items: 1,
    stepIndicator: false,
    dots: false,
    lazyLoad: true,
    loop: false,
    nav: false,
  },
  gallery: {
    items: 1,
    lazyLoad: true,
    navContainerClass: 'owl-nav',
    controlsClass: 'owl-controls gallery__controls row expand',
  },
  'one-up': {
    items: 1,
    stepIndicator: true,
    dots: true,
    lazyLoad: true,
    loop: false,
    nav: false,
    navContainerClass: '',
  },
  'family-highlight': {
    items: 1,
    stepIndicator: false,
    dots: true,
    lazyLoad: true,
    loop: true,
    nav: true,
    navContainerClass: 'owl-nav pagination',
  },
  'subfamily-carousel': {
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      960: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  },
  'four-up': {
    responsive: {
      0: {
        items: 1,
        stagePadding: 28,
      },
      480: {
        items: 2,
        stagePadding: 28,
      },
      600: {
        items: 3,
        stagePadding: 45,
      },
      960: {
        items: 4,
        stagePadding: 0,
      },
    },
  },
  'three-up': {
    navContainerClass: 'owl-nav',
    responsive: {
      0: {
        items: 1,
        stagePadding: 0,
      },
      600: {
        items: 2,
        stagePadding: 0,
      },
      960: {
        items: 3,
        stagePadding: 0,
      },
    },
  },
  'three-up-tertiary': {
    navContainerClass: 'owl-nav',
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    loop: false,
    responsive: {
      0: {
        margin: 20,
        items: 1,
        stagePadding: 32,
      },
      640: {
        margin: 30,
        items: 2,
        stagePadding: 64,
      },
      1024: {
        margin: 30,
        items: 3,
        stagePadding: 64,
      },
    },
  },
  'four-up-tertiary': {
    navContainerClass: 'owl-nav',
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    loop: false,
    responsive: {
      0: {
        margin: 20,
        items: 1,
        stagePadding: 32,
      },
      640: {
        margin: 30,
        items: 2,
        stagePadding: 64,
      },
      1024: {
        margin: 30,
        items: 4,
        stagePadding: 64,
      },
    },
  },
  'pdp-sizing': {
    nav: false,
    stepIndicator: false,
    dots: true,
    dotsEach: 1,
    slideBy: 1,
    margin: 0,
    dotsContainer: '.rider-sizing__dots',
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 5,
      },
      960: {
        items: 8,
      },
    },
  },
};

// eslint-disable-next-line no-unused-vars
function chatty(e) {
  window.console.log([
    //e.target,
    'Event: ' + e.type,
    //e.namespace,
    //e.item.count,
    'item index: ' + e.item.index,
    'page count: ' + e.page.count,
    'page index: ' + e.page.index,
    'page size: ' + e.page.size,
  ]);
}

export default function SliderFactory(slider) {
  var mySlider;
  var sliderDict = {
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      960: {
        items: 4,
      },
    },
  };

  if (slider.data('owlSlider') === 'four-up') {
    sliderDict.responsive[0].items = slider.data('slides-visible-small');
    sliderDict.responsive[600].items = slider.data('slides-visible-medium');
    sliderDict.responsive[960].items = slider.data('slides-visible-large');
    mySlider = slider.owlCarousel($.extend({}, DEFAULTS, sliderDict));
  } else if (slider.data('owlSlider') === 'pdp-sizing') {
    var sizingDict = DICT['pdp-sizing'];
    sizingDict.responsive[0].items = slider.data('slides-visible-small');
    sizingDict.responsive[600].items = slider.data('slides-visible-medium');
    sizingDict.responsive[960].items = slider.data('slides-visible-large');
    mySlider = slider.owlCarousel($.extend({}, DEFAULTS, sizingDict));
  } else {
    mySlider = slider.owlCarousel($.extend({}, DEFAULTS, DICT[slider.data('owlSlider')]));
  }
  /*******************
   * According to OWL carousel rlease notes owl-controls wrapper was removed
   * from project even to simplify the configuration.
   * Even though the docs say it still works, it doesn't.
   *******************/
  slider.find('.owl-nav, .owl-dots').wrapAll('<div class="owl-controls">');

  return mySlider;
}
