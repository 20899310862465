'use strict';

export default function (element) {
  var tag = document.createElement('script');

  tag.src = 'https://www.youtube.com/iframe_api';
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //setup the HTML structure for multiple videos with unique identifiers based on the number of items in element
  $.each(element, function (i, val) {
    var $background = $('<div>').addClass('video-background');
    var $foreground = $('<div>').addClass('video-foreground');
    var $player = $('<div>').attr('id', $(val).data('video'));
    $(val).append($background.append($foreground.append($player)));
  });

  // Streches the container to acheive full background video based on aspect ratio of container and video
  var checkAspectRatio = function ($element) {
    if ($element.width() / $element.height() > 16 / 9) {
      $('.video-foreground').css('height', '300%');
      $('.video-foreground').css('top', '-100%');
      $('.video-foreground').css('width', '100%');
      $('.video-foreground').css('left', '0');
    } else if ($element.width() / $element.height() < 16 / 9) {
      $('.video-foreground').css('width', '300%');
      $('.video-foreground').css('left', '-100%');
      $('.video-foreground').css('height', '100%');
      $('.video-foreground').css('top', '0');
    } else {
      $('.video-foreground').css('height', '100%');
      $('.video-foreground').css('top', '0');
      $('.video-foreground').css('width', '100%');
      $('.video-foreground').css('left', '0');
    }
  };

  checkAspectRatio(element);

  // Create multiple iframes for each video once the youTube iframe api has loaded
  window.onYouTubeIframeAPIReady = function () {
    $.each(element, function (i, val) {
      var myVid = $(val).data('video');
      new window.YT.Player(myVid, {
        videoId: myVid,
        playerVars: {
          controls: 0,
          showinfo: 0,
          rel: 0,
          autoplay: 1,
          cc_load_policy: 0,
          playsinline: 1,
          loop: 1,
          mute: 1,
        },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    });
  };

  function onPlayerReady(event) {
    checkAspectRatio(element);
    event.target.mute();
    event.target.playVideo();
  }

  function onPlayerStateChange(event) {
    // This is to keep the video running after state change or switching apps on mobile too
    event.target.mute();
    event.target.playVideo();
  }

  $(window).on('resize', function () {
    checkAspectRatio(element);
  });
}
