import {Price} from '@/components/containers/pdp/utils/price';

class PriceRange {
  static create(price) {
    if (price?.low && price?.high) {
      return new PriceRange(price?.low, price?.high);
    } else if (price?.low) {
      return new Price(price?.low);
    } else {
      return new Price(price?.high);
    }
  }

  /**
   * Initialize a new instance of PriceRange object.
   * @param low
   * @param high
   */
  constructor(low, high) {
    this.low = new Price(low);
    this.high = new Price(high);
  }

  /**
   * Check whether the current price has a range. Returns false if high value not set or equal to low value.
   * @return {boolean|boolean}
   */
  hasRange() {
    return this.low.value < this.high.value && this.high.value !== null;
  }

  /**
   * Get formatted display price (range or a single value).
   * @return {*|string}
   */
  get displayPrice() {
    if (this.low.value === null) {
      return '';
    }

    let displayPrice = this.low.formatted;
    if (this.hasRange()) {
      displayPrice = `${displayPrice} - ${this.high.formatted}`;
    }

    return displayPrice;
  }
}

export {PriceRange};
