import find from 'lodash/find';
import {mapState, mapActions} from 'vuex';
import {PdlToastType} from '@/constants/pdl-toast-type';

const CompareButton = {
  computed: {
    ...mapState('backend', ['unitId']),
    ...mapState('compare', ['racks']),

    compareButtonText() {
      if (this.hover && this.isRackFull) {
        return this.isInRack ? this.$t('text.remove') : this.$t('productCompare.fullRack.error');
      } else {
        return this.isInRack ? this.$t('text.remove') : this.$t('productCompare.compare');
      }
    },

    compareButtonIconProps() {
      if (this.flashAdded) {
        return {
          icon: 'add_circle',
          iconSuccess: true,
        };
      } else if (this.flashRemoved) {
        return {
          icon: 'remove_circle',
          iconAlert: true,
        };
      } else if (this.hover && this.isRackFull) {
        return {
          icon: this.isInRack ? 'remove_circle_outline' : 'remove_circle',
          iconAlert: this.isInRack,
        };
      } else {
        return {
          icon: this.isInRack ? 'remove_circle_outline' : 'add_circle_outline',
          iconAlert: this.isInRack,
        };
      }
    },

    isInRack() {
      if (!this.racks || !Object.keys(this.racks).length || !(this.unitId in this.racks)) {
        return false;
      }

      return !!find(this.racks[this.unitId], {productCode: this.baseProductId});
    },

    isRackFull() {
      return (
        this.racks &&
        Object.keys(this.racks).length > 0 &&
        this.unitId in this.racks &&
        this.racks[this.unitId].length === 4
      );
    },
  },

  methods: {
    ...mapActions('compare', ['addToRack', 'removeFromRack']),

    onCompareClick() {
      const product = {
        productName: this.productName,
        imageUrl: this.imageUrl,
        productCode: this.productCode,
        productUrl: this.productUrl,
      };

      if (this.isInRack) {
        this.flashRemoved = true;
        setTimeout(() => {
          this.flashRemoved = false;
        }, this.flashTime);
        return this.removeFromRack(product);
      }

      if (!this.isRackFull) {
        this.flashAdded = true;
        setTimeout(() => {
          this.flashAdded = false;
        }, this.flashTime);
        return this.addToRack(product);
      } else {
        this.$notify({
          dangerouslyUseHTMLString: true,
          type: PdlToastType.ERROR,
          message: this.$t('productCompare.fullRack.error'),
        });
      }
    },
  },

  data() {
    return {
      hover: false,
      flashAdded: false,
      flashRemoved: false,
      flashTime: 1000,
    };
  },
};

export {CompareButton};
