class Price {
  /**
   * Initialize a new instance of Price object.
   * @param data
   */
  constructor(data) {
    this.value = data?.value ?? data?.low?.value;
    this.formatted = data?.formatted ?? data?.low?.formatted;
  }

  get displayPrice() {
    return this.formatted;
  }
}

export {Price};
