<template>
  <div>
    <template v-if="!isKcp">
      <div class="mb-3 leading-normal" qaid="review-your-order">
        {{ $t('checkout.summary.reviewOrder.2') }} {{ $t('checkout.summary.reviewOrder.1') }}
      </div>
      <div class="mb-3" qaid="terms-text">
        {{ $t('checkout.summary.termsAndConditions6') }}
        <pdl-link qaid="terms-link" :href="termsLink" target="_blank">
          {{ $t('checkout.summary.termsAndConditions2') }}
        </pdl-link>
      </div>
    </template>

    <form-group
      :disabled="false"
      error=""
      :multi-items="items"
      name="termsAndConditionsCheckboxes"
      :required="true"
      show-error-messages
      show-label
      type="checkbox"
      :label-classes="'text-sm'"
      :warning="false"
      @change="handleCheck"
    />

    <div class="flex flex-col gap-2">
      <pdl-button
        id="orderSubmitButtonV2"
        qaid="order-submit-button"
        :disabled="!hasAgreedToTerms"
        primary
        type="submit"
      >
        <span>{{ $t('checkout.summary.payForYourOrder') }}</span>
      </pdl-button>
      <pdl-link qaid="cart-continue-link" :href="homePageUrl" button tertiary block icon-right icon="chevron_right">
        {{ $t('cart.page.continue') }}
      </pdl-link>
    </div>
  </div>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
import {PdlLink} from '@pedal/pdl-link';
import {PdlButton} from '@pedal/pdl-button';
import FormGroup from '@/components/FormGroup';
import isEmpty from 'lodash/isEmpty';
import CheckboxConfiguration from '@/modules/consent-checkboxes';

const TERMS_CHECKED_EVENT = 'requiredTermsChecked';

export default {
  name: 'OrderReviewTermsAndConditions',
  components: {PdlLink, PdlButton, FormGroup},
  props: {
    homePageUrl: {
      type: String,
      required: true,
    },
    dotsTermsUrl: {
      type: String,
      required: false,
      default: '',
    },
    isDotsUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkboxConfig: undefined,
    };
  },
  computed: {
    ...mapState('backend', ['locale', 'isUserLoggedIn', 'encodedContextPath']),
    ...mapGetters('checkout', ['isRestrictedToRetailer']),

    items() {
      return this.checkboxConfig?.getCheckboxList();
    },
    isKcp() {
      return this.locale === 'kr';
    },
    hasAgreedToTerms() {
      return this.checkboxConfig?.getIsAllRequiredChecked();
    },
    isTranslationLoaded() {
      return !isEmpty(window.VueI18n.messages);
    },
    termsLink() {
      if (!this.isRestrictedToRetailer) {
        return `${this.encodedContextPath}/company/legal_policies/privacy_policy_terms_of_use/`;
      }

      return this.isDotsUser ? this.dotsTermsUrl : `${this.encodedContextPath}/trek-u-terms-conditions/`;
    },
  },
  watch: {
    isTranslationLoaded(updateValue, prevValue) {
      if (!prevValue && updateValue) {
        this.checkboxConfig = CheckboxConfiguration(this.locale, this.isUserLoggedIn).getOrderReviewConfiguration();
        if (this.checkboxConfig.getIsEmpty()) this.$emit(TERMS_CHECKED_EVENT, true);
      }
    },
  },
  methods: {
    handleCheck(ev) {
      if (ev.srcElement.name === 'allTermsCheck') {
        this.checkboxConfig?.toggleAll();
      } else {
        this.checkboxConfig?.toggleCheckbox(ev.srcElement.name);
      }
    },
  },
};
</script>
