/**
 * Import this into your component for analytics tracking.
 * Trek's Generic track event that takes an object to send off to analytics.
 * Object should be formatted and setup for the analytics api to consume.
 * More api info can be found here. https://developers.google.com/analytics/devguides/collection
 * @param {Object} data
 */
const trackEvent = (data) => {
  if (typeof dataLayer === 'undefined') return;

  // clear out previous event
  window.dataLayer.push({ecommerce: null});

  // push current event
  window.dataLayer.push(data);
};

export {trackEvent};
