import {ProductPriceData} from '@/components/containers/pdp/utils/product-price-data';
import get from 'lodash/get';

class ConsumerProductPriceData extends ProductPriceData {
  /**
   * Init values of consumer display price.
   * @param displayPrice
   * @return {*}
   */
  calculateDisplayPrice() {
    let displayPrice = super.calculateDisplayPrice();

    const saleAdvertised = get(this.saleAdvertised, 'low.value');
    const advertised = get(this.advertised, 'low.value');
    const apple = get(this.apple, 'low.value');

    if (!saleAdvertised && !advertised && !apple) {
      return displayPrice;
    }

    if (saleAdvertised > 0 && saleAdvertised < apple && apple < advertised) {
      displayPrice.value = this.apple.displayPrice;
      displayPrice.oldPrice = this.advertised.displayPrice;
      displayPrice.isSale = true;
    } else if (apple > 0) {
      displayPrice.oldPrice = '';

      if (apple === advertised) {
        displayPrice.value = this.advertised.displayPrice;
      } else if (apple > advertised) {
        displayPrice.value = this.apple.displayPrice;
      } else if (apple <= saleAdvertised) {
        displayPrice.value = this.saleAdvertised.displayPrice;
        displayPrice.oldPrice = this.advertised.displayPrice;
        displayPrice.isSale = true;
      }
    }

    if (!this.options.b2b && advertised > 0) {
      const price = get(this.price, 'low.value');
      const wasPrice = get(this.wasPrice, 'value');

      if (wasPrice < advertised && price === wasPrice) {
        displayPrice.oldPrice = this.advertised.displayPrice;
        displayPrice.isSale = true;
      }
    }

    return displayPrice;
  }
}

export {ConsumerProductPriceData};
