<template>
  <div>
    <div v-if="isCustomizeLinkVisible" class="cart-select-p1">
      <component :is="customizeComponent" qaid="customize-btn" :class="classes" :href="projectOneConfiguratorUrl">
        <pdl-icon class="project-one-icon" name="project_one_colorwheel" />
        {{ $t('projectOne.button.customize') }}
      </component>
    </div>
    <div v-else-if="isSpectrumEnabled">
      <trek-button
        qaid="cart-select-spectrum"
        block
        :disabled="hasCustomWaterBottleRestriction"
        icon="build"
        @click="openSpectrumCustomizer"
      >
        {{ $t('product.details.buyingzone.customize') }}
      </trek-button>
    </div>
    <spectrum-customizer-overlay
      v-if="isSpectrumEnabled"
      ref="spectrumCustomizerOverlay"
      qaid="spectrum-customizer-overlay"
      :has-spectrum-open="hasSpectrumOpen"
      :spectrum-url="spectrumUrl"
      :options="options"
      :custom-product-starting-point="customProductStartingPoint"
      @close-spectrum-customizer="closeSpectrumCustomizer"
    ></spectrum-customizer-overlay>
  </div>
</template>
<script>
import {Url} from '@/utils/url';

import SpectrumCustomizerOverlay from '@/components/customizer/SpectrumCustomizerOverlay';
import TrekButton from '@/components/TrekButton.vue';
import {mapState, mapGetters, mapMutations} from 'vuex';
import {PdlIcon} from '@pedal/pdl-icon';

export default {
  name: 'PdpCustomizeProductButton',
  components: {
    TrekButton,
    SpectrumCustomizerOverlay,
    PdlIcon,
  },
  props: {
    projectOneUrl: {
      type: String,
      default: '',
    },
    spectrumUrl: {
      type: String,
      default: '',
    },
    customProductStartingPoint: {
      type: String,
      default: '',
    },
    customConfiguratorType: {
      type: String,
      default: '',
    },
    siteLocale: {
      type: String,
      default: '',
    },
    isProjectOneConfiguratorActive: {
      type: Boolean,
      default: false,
    },
    isCustomizable: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: () => null,
    },
    successMessage: {
      type: String,
      default: '',
    },
    isB2b: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasSpectrumOpen: false,
    };
  },
  computed: {
    ...mapGetters('checkout', ['isRestrictedToRetailer']),

    isProjectOneEnabled() {
      return (
        this.isProjectOneConfiguratorActive &&
        this.customProductStartingPoint &&
        this.customConfiguratorType === 'ProjectOne'
      );
    },
    isLegacyProjectOneEnabled() {
      return (
        !!this.projectOneUrl && !this.isProjectOneConfiguratorActive && this.customConfiguratorType === 'ProjectOne'
      );
    },
    isSpectrumEnabled() {
      if (this.spectrumUrl && this.customConfiguratorType === 'SpectrumBottleConfigurator')
        this.setIsSpectrumEnabled(true);
      return !!this.spectrumUrl && this.customConfiguratorType === 'SpectrumBottleConfigurator';
    },
    projectOneConfiguratorUrl() {
      let url = '';
      if (this.isProjectOneEnabled) {
        if (this.isB2b) {
          url = `${this.siteLocale}/project-one-builder/#/${this.customProductStartingPoint}`;
        } else {
          url = `/${this.siteLocale}/project-one-builder/#/${this.customProductStartingPoint}`;
        }
      } else if (this.isLegacyProjectOneEnabled) {
        url = this.projectOneUrl;
      }
      return url;
    },
    customizeComponent() {
      return this.isCustomizable ? 'a' : 'span';
    },
    classes() {
      return {
        button: true,
        'button--secondary': true,
        'button--block': true,
        'button--disabled': !this.isCustomizable,
      };
    },

    isCustomizeLinkVisible() {
      return (this.isProjectOneEnabled || this.isLegacyProjectOneEnabled) && !this.isRestrictedToRetailer;
    },

    ...mapState('backend', ['hasCustomWaterBottleRestriction']),
  },
  created() {
    this.checkIfSpectrumCustomizerIsPreOpened();
  },
  methods: {
    checkIfSpectrumCustomizerIsPreOpened() {
      const url = new URLSearchParams(document.location.search);
      const hasSpectrumOpen = url.get('hasSpectrumOpen');
      if (hasSpectrumOpen === 'true') {
        this.$nextTick(() => {
          this.removeRedirectParametersFromUrl();
          this.openSpectrumCustomizer();
        });
      }
    },
    removeRedirectParametersFromUrl() {
      window.history.replaceState(
        null,
        document.title,
        Url.update(window.location.href, undefined, {
          removeKeys: ['hasSpectrumOpen'],
        })
      );
    },
    removeRecipeIdFromUrl() {
      window.history.replaceState(
        null,
        document.title,
        Url.update(window.location.href, undefined, {
          removeKeys: ['recipeId'],
        })
      );
    },
    openSpectrumCustomizer() {
      this.hasSpectrumOpen = !this.hasSpectrumOpen;
    },
    closeSpectrumCustomizer(value) {
      this.removeRecipeIdFromUrl();
      this.hasSpectrumOpen = value;
    },
    ...mapMutations('backend', ['setIsSpectrumEnabled']),
  },
};
</script>

<style scoped>
.project-one-icon {
  margin-right: 0.2rem;
}
</style>
