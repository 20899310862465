import {mapState} from 'vuex';
import {trackEvent} from '@/utils/tracking';

const trackProductTile = {
  computed: {
    ...mapState('backend', ['pageTitle', 'b2b']),

    setProductSource() {
      return this.isCarousel ? this.sectionType : this.pageTitle;
    },
  },

  methods: {
    trackProductClick() {
      if (this.b2b || this.product.isArchived) {
        return;
      }

      const trackProductClickData = {
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: {list: this.setProductSource},
            products: [
              {
                name: this.product.displayName,
                id: this.product.code,
                price: this.product.price.value,
                brand: this.product.brandNameFull,
                category: this.product.defaultCategory,
                variant: this.currentColor,
                position: this.itemId,
              },
            ],
          },
        },
      };

      trackEvent(trackProductClickData);
    },
  },
};

export default trackProductTile;
