<template>
  <div v-if="isB2BPurchasable" class="primary-navigation__cart siteMenu__button">
    <button
      id="minicartTrigger__button"
      ref="miniCartButton"
      v-tippy="miniCartTooltipConfig"
      class="header-bottom__cart-button menu-trigger flex"
      :title="$t('cartPage')"
      data-ga-cart="cart"
      :data-ga-label="totalItems"
      @show="updateMiniCart"
      @click="addTooltipEventListener"
    >
      <pdl-icon name="shopping_cart" qaid="mini-cart-icon" :label="$t('myCart.B2B.title')"></pdl-icon>
      <pdl-badge
        v-if="totalItems > 0"
        id="miniCartItemCount"
        qaid="mini-cart-badge"
        class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        :value="totalItems"
      />
    </button>

    <!-- Mini Cart Tooltip (Dropdown) -->
    <div v-cloak id="miniCartTooltip" class="b2b-mini-cart js-miniCartTooltip overflow-y-auto">
      <p v-if="isLoading" class="order-tile-checkout__heading text-xstrong">{{ $t('text.loadingMessage') }}</p>
      <template v-else>
        <div v-if="isInContainerConfigMode">
          <ContainerConfiguration
            :base-properties="baseProperties"
            :drop-ship-container="cart.dropShipContainer"
            @close="isInContainerConfigMode = false"
          />
        </div>
        <div v-else>
          <!-- Content -->
          <div v-if="isCartEmpty">
            <pdl-heading id="miniCart__callout--empty" class="mb-3" :level="1" size="sm">
              {{ $t('popup.cart.empty') }}
            </pdl-heading>

            <div v-if="containers">
              <drop-ship-orders-container
                :drop-ship-container="cart.dropShipContainer"
                :size="containerPageSize"
                :is-mini-cart="true"
                @add-container="isInContainerConfigMode = true"
              />
            </div>
          </div>

          <div v-else>
            <div v-if="containers">
              <drop-ship-orders-container
                :base-properties="baseProperties"
                :drop-ship-container="containers"
                :size="containerPageSize"
                :is-mini-cart="true"
                @add-container="isInContainerConfigMode = true"
              />
            </div>

            <div id="miniCart__itemList--combined">
              <mini-cart-item
                v-if="bikes && bikes.quantity"
                :base-properties="baseProperties"
                :cart-heading="$t('text.bikes')"
                :quantity="bikes.quantity"
                :price-value="bikes.prices.totalPrice.formattedValue"
              />

              <mini-cart-item
                v-if="parts && parts.quantity"
                :base-properties="baseProperties"
                :cart-heading="$t('text.accessories')"
                :quantity="parts.quantity"
                :price-value="parts.prices.totalPrice.formattedValue"
              />

              <mini-cart-item
                v-if="customWaterBottles && customWaterBottles.quantity"
                :base-properties="baseProperties"
                :cart-heading="$t('checkout.custom')"
                :quantity="customWaterBottles.quantity"
                :price-value="customWaterBottles.prices.totalPrice.formattedValue"
              />

              <mini-cart-item
                v-if="projectOneBikes && projectOneBikes.quantity"
                :base-properties="baseProperties"
                :cart-heading="$t('checkout.projectOne')"
                :quantity="projectOneBikes.quantity"
                :price-value="projectOneBikes.prices.totalPrice.formattedValue"
              />
            </div>

            <div v-if="!isConsumerFriendlyMode" class="b2b-mini-cart__total font-medium">
              {{ $t('text.total') }}
              <span id="miniCart__total" class="font-bold" v-html="totalPriceFormattedValue" />
            </div>
          </div>

          <!-- Footer/Buttons -->

          <!-- Empty cart buttons -->
          <div v-if="isCartEmpty">
            <!-- Empty cart with allocated items -->
            <div v-if="hasAllocatedItems" class="buttons mt-3">
              <!-- TODO: Refactor. For checkout it is only one entry point /checkout/
                        for allocated-items and shipment checkouts -->
              <trek-link id="miniCart__allocatedItems" button secondary block :href="`${encodedContextPath}/checkout/`"
                ><span v-html="$t('myCart.B2B.emptyCart.itemsAllocated')"></span
              ></trek-link>

              <trek-link
                id="miniCart__bulkUploadBtn"
                button
                secondary
                block
                :href="`${encodedContextPath}/import/csv/saved-cart`"
                ><span v-html="$t('bulkOrder.upload')"></span
              ></trek-link>

              <!-- TODO: Refactor. For checkout it is only one entry point /checkout/
                        for allocated-items and shipment checkouts -->
              <trek-link
                id="miniCart__checkoutBtn"
                button
                primary
                block
                :href="`${encodedContextPath}/checkout/`"
                :disabled="!isCheckoutEnabled"
                @click="checkoutDisabledCheck"
                ><span v-html="$t('text.checkout')"></span
              ></trek-link>
            </div>

            <!-- Empty cart without allocated items -->
            <trek-link
              v-else
              id="miniCart__bulkOrderUploadBtn"
              button
              primary
              block
              :href="`${encodedContextPath}/import/csv/saved-cart`"
              class="mb-0"
              ><span v-html="$t('bulkOrder.upload')"></span
            ></trek-link>
          </div>

          <!-- Cart with items buttons -->
          <div v-else class="buttons mt-3">
            <trek-link id="miniCart__ViewCartBtn" button secondary block :href="`${encodedContextPath}/cart`"
              ><span v-html="$t('cart.view')"></span
            ></trek-link>

            <!-- TODO: Refactor. For checkout it is only one entry point /checkout/
                      for allocated-items and shipment checkouts -->
            <trek-link
              id="miniCart__checkoutBtn"
              button
              primary
              block
              :href="`${encodedContextPath}/checkout/`"
              :disabled="!isCheckoutEnabled"
              @click="checkoutDisabledCheck"
              ><span v-html="$t('text.checkout')"></span
            ></trek-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import TrekLink from '@/components/TrekLink';
import MiniCartItem from '@/components/mini-cart/b2b/MiniCartItem';
import DropShipOrdersContainer from '@/components/containers/drop-ship/DropShipOrdersContainer';
import ContainerConfiguration from '@/components/containers/drop-ship/ContainerConfiguration';
import {PdlBadge} from '@pedal/pdl-badge';
import {PdlHeading} from '@pedal/pdl-section-header';
import {PdlIcon} from '@pedal/pdl-icon';
import {CheckoutMixin} from '@/components/checkout/mixins/checkout.js';

export default {
  name: 'B2bMiniCart',
  components: {PdlHeading, DropShipOrdersContainer, ContainerConfiguration, TrekLink, MiniCartItem, PdlBadge, PdlIcon},
  mixins: [CheckoutMixin],

  props: {
    baseProperties: {
      type: Array,
      required: false,
      default: () => [],
    },
    containerPageSize: {
      type: Number,
      required: false,
      default: 4,
    },
    totalQuantity: {
      type: Number,
      required: false,
      default: 0,
    },
    isCheckoutAllowed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isInContainerConfigMode: false,
      miniCartTooltipConfig: {
        html: '.js-miniCartTooltip',
        interactive: true,
        reactive: true,
        trigger: 'click',
        placement: 'bottom-end',
        theme: 'flush',
        flip: true,
        sticky: true,
        popperOptions: {
          positionFixed: true,
          modifiers: {
            keepTogether: true,
            preventOverflow: {
              enabled: false,
            },
            hide: {
              enabled: false,
            },
          },
        },
      },
      isLoading: true,
    };
  },

  computed: {
    ...mapState('miniCart', ['bikes', 'parts', 'customWaterBottles', 'projectOneBikes', 'cart', 'items', 'containers']),
    ...mapState('backend', ['isB2BPurchasable', 'encodedContextPath', 'isConsumerFriendlyMode']),
    ...mapState('checkout', ['hasReachedBikePurchaseLimit']),
    ...mapState('user', ['trekUCompetencyLevel']),
    ...mapGetters('checkout', ['isRestrictedToRetailer']),

    totalItems() {
      return this.cart.details ? this.cart.details?.totalQuantity : this.totalQuantity;
    },

    isCartEmpty() {
      return this.cart.details?.totalQuantity === 0;
    },

    hasAllocatedItems() {
      return this.cart.details?.hasAllocatedItems;
    },

    isCheckoutEnabled() {
      return (this.cart && this.cart.details?.totalQuantity) || (this.cart && this.cart.details?.hasAllocatedItems);
    },

    totalPriceFormattedValue() {
      return this.cart.prices?.totalPrice?.formattedValue || '';
    },
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.closeTooltip);
  },

  methods: {
    async updateMiniCart() {
      await this.fetchMiniCart();
      this.isLoading = false;
    },

    checkoutDisabledCheck(event) {
      if (!this.isCheckoutEnabled) {
        event.preventDefault();
      }

      if (!this.isRestrictedToRetailer) return;

      if (!this.isCheckoutAllowed) {
        event.preventDefault();
        this.showTrekUOfflineToastMessage();
      } else if (this.hasReachedBikePurchaseLimit) {
        event.preventDefault();
        this.showBikeLimitReachedMessage();
      } else if (this.trekUCompetencyLevel === undefined) {
        event.preventDefault();
        this.showNoCompetencyLevelMessage();
      }
    },

    addTooltipEventListener() {
      document.addEventListener('keyup', this.closeTooltip);
    },

    closeTooltip(event) {
      const miniCartButton = this.$refs['miniCartButton'];
      if (event.key === 'Escape' && miniCartButton && miniCartButton._tippy) {
        miniCartButton._tippy.hide();
        document.removeEventListener('keyup', this.closeTooltip);
      }
    },

    ...mapActions('miniCart', ['fetchMiniCart']),
  },
};
</script>
