import {PdlToastType} from '@/constants/pdl-toast-type';

const CheckoutMixin = {
  methods: {
    /**
     * This method includes methods to show TrekU offline checkout toast message
     */
    showTrekUOfflineToastMessage() {
      return this.$notify({
        type: PdlToastType.INFO,
        message: this.$t('checkout.B2B.trekU.unavailable'),
      });
    },

    showBikeLimitReachedMessage() {
      return this.$notify({
        type: PdlToastType.ERROR,
        message: this.$t('employeePurchase.B2B.quantityLimit.removeToProceed'),
      });
    },
    showNoCompetencyLevelMessage() {
      return this.$notify({
        type: PdlToastType.ERROR,
        message: this.$t('checkout.B2B.trekU.noCompetency'),
      });
    },
  },
};

export {CheckoutMixin};
