<template>
  <pdl-label-group qaid="product-label-group">
    <pdl-label v-if="isArchived" kind="default" qaid="product-label-archive">
      {{ $t('product.archived.flag') }}
    </pdl-label>
    <pdl-label v-if="hasB2cSaleFlag" kind="critical" qaid="product-label-b2c-sale">
      {{ $t('product.sale.flag') }}
    </pdl-label>
    <pdl-label v-if="hasB2bSaleFlag" kind="critical" qaid="product-label-b2b-sale">
      {{ $t('product.sale.flag') }}
    </pdl-label>
    <pdl-label v-if="hasCPOLabel" kind="positive" qaid="product-label-CPO">
      {{ $t('product.CPO.flag') }}
    </pdl-label>
    <pdl-label v-if="hasB2bEarlyIntroFlag" kind="default" qaid="product-label-b2b-early-intro">
      {{ $t('text.buyingZone.earlyIntro') }}
    </pdl-label>
    <pdl-label v-if="hasB2bCloseoutFlag" kind="important" qaid="product-label-b2b-closeout">
      {{ $t('text.buyingZone.closeout') }}
    </pdl-label>
    <pdl-label v-if="hasProductCalloutNew" kind="default" qaid="product-label-callout-new">
      {{ $t('product.new.flag') }}
    </pdl-label>
    <pdl-label v-if="hasProductCallout" kind="default" qaid="product-label-callout">
      {{ product.productCallout }}
    </pdl-label>
    <pdl-label v-if="hasPreOrderLabel" kind="prominent" qaid="product-label-pre-order">
      {{ $t('product.preOrder.flag') }}
    </pdl-label>
  </pdl-label-group>
</template>
<script>
import {PdlLabel, PdlLabelGroup} from '@pedal/pdl-label';
import Variant from '@/utils/variant-helpers.js';
import {mapState} from 'vuex';
export default {
  components: {
    PdlLabel,
    PdlLabelGroup,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    isB2bPdp: Boolean,
  },
  computed: {
    isArchived() {
      return !!this.product.isArchived;
    },
    hasB2cSaleFlag() {
      return this.product.saleFlag && !this.isB2bPdp;
    },
    hasB2bSaleFlag() {
      return !!(
        this.isB2bPdp &&
        this.product.productBadgeStatus == 'S' &&
        (this.pdpSku ? this.isSelectedVariantOnSale : true)
      );
    },
    isSelectedVariantOnSale() {
      return Variant(this.pdpSku, this.product, this.isB2bPdp).getIsOnSale();
    },
    hasB2bEarlyIntroFlag() {
      return !!(this.isB2bPdp && this.product.productBadgeStatus == 'E');
    },
    hasB2bCloseoutFlag() {
      return !!(this.isB2bPdp && this.product.productBadgeStatus == 'C');
    },
    hasProductCalloutNew() {
      return !!(
        this.product.productCallout &&
        this.product.productCallout.length > 0 &&
        this.product.productCallout.toLowerCase() === 'new'
      );
    },
    hasProductCallout() {
      return !!(
        this.product.productCallout &&
        this.product.productCallout.length > 0 &&
        this.product.productCallout.toLowerCase() !== 'new'
      );
    },
    hasCPOLabel() {
      return this.product.isCPO;
    },
    hasPreOrderLabel() {
      return this.product?.preOrderFlag;
    },
    ...mapState('pdp', ['pdpSku']),
  },
};
</script>
