<template>
  <aside :class="!hideProductListAndButtons ? 'h-full' : ''">
    <pdl-collapse :default-active="viewBreakpointMoreThan('small')" header-classes="text-md font-bold" class="mb-2">
      <pdl-collapse-item>
        <template #title>
          <div>
            <span class="font-bold text-lg header">{{ $t('checkout.multi.summary') }}</span>
            <span class="text-gray-60 text-md font-normal">{{ `(${cartData.code})` }}</span>
          </div>
        </template>
        <div class="content">
          <cart-item-list
            v-if="!hideProductListAndButtons"
            :is-e-comm-available="false"
            :cart-entries="cartEntries"
            :cart-data="cartData"
            :is-quantity-control-enabled="false"
          />
          <div v-if="!hideProductListAndButtons" class="mt-2 md:hidden">
            <pdl-link button tertiary icon="chevron_left" icon-left :href="cartUrl" qaid="back-to-cart-link">
              {{ $t('pickup.mobile.back.to.cart.page') }}
            </pdl-link>
          </div>
          <aside-cart-totals
            :cart-data="cartData"
            :is-calculated-tax-visible="isCalculatedTaxVisible"
            :trek-u-competency-level="trekUCompetencyLevel"
          />
        </div>
      </pdl-collapse-item>
    </pdl-collapse>
    <div class="md:block md:sticky md:top-16" :class="!hideProductListAndButtons ? 'hidden' : ''">
      <div v-if="!hideProductListAndButtons" class="grid grid-flow-row gap-y-2 mb-8">
        <checkout-submit-button
          v-if="stepId === 'paymentMethod'"
          :step-id="stepId"
          :card-type="cardType"
          :is-new-payment="isNewPayment"
        />
        <pdl-button
          v-if="stepId === 'deliveryAddress' && !isSavedAddressLayout"
          block
          primary
          type="submit"
          qaid="choose-payment-method-button"
          form="trekAddressForm"
        >
          {{ $t('checkout.summary.select.payment.method') }}
        </pdl-button>
        <pdl-button
          v-if="stepId === 'deliveryAddress' && isSavedAddressLayout"
          id="chooseDeliveryMethodBtn"
          block
          primary
          type="submit"
          form="deliveryAddressFormPage"
          qaid="choose-payment-method-button"
          :disabled="!selectedAddressCode"
          @click="trackAddressCheckoutSteps"
        >
          {{ $t('checkout.summary.select.payment.method') }}
        </pdl-button>
        <pdl-link
          button
          tertiary
          block
          icon="chevron_right"
          qaid="checkout-continue-link"
          icon-right
          :href="continueShoppingUrl"
        >
          {{ $t('text.continue.shopping') }}
        </pdl-link>
      </div>
      <slot />
    </div>
  </aside>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex';
import {PdlButton} from '@pedal/pdl-button';
import {PdlCollapse, PdlCollapseItem} from '@pedal/pdl-collapse';
import {PdlLink} from '@pedal/pdl-link';
import AsideCartTotals from '@/components/checkout/order-summary-aside/AsideCartTotals.vue';
import CartItemList from '@/components/cart/b2c/CartItemList.vue';
import CheckoutSubmitButton from '../fields/CheckoutSubmitButton.vue';
import trackCheckout from '@/mixins/tracking/track-checkout.js';
import {UserApi} from '@/api/user';

export default {
  components: {CartItemList, CheckoutSubmitButton, PdlButton, PdlCollapse, PdlCollapseItem, PdlLink, AsideCartTotals},
  mixins: [trackCheckout],
  props: {
    isNewAddress: {
      type: Boolean,
      default: false,
    },
    cartData: {
      type: Object,
      required: true,
    },
    isPaymentMethodVisible: {
      type: Boolean,
      default: true,
    },
    isCalculatedTaxVisible: {
      type: Boolean,
      default: true,
    },
    stepId: {
      type: String,
      default: '',
    },
    hideProductListAndButtons: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('backend', ['trekBrandNavURL']),
    ...mapState('checkout', ['paymentData', 'continueShoppingUrl', 'selectedAddressCode']),
    ...mapState('user', ['trekUCompetencyLevel']),
    ...mapGetters('viewSize', ['viewBreakpointMoreThan']),

    cardType() {
      return this.paymentData?.cardType;
    },
    cartEntries() {
      return this.cartData?.entries;
    },
    cartUrl() {
      return `${this.trekBrandNavURL}/cart`;
    },
  },
  async created() {
    try {
      const response = await UserApi.getCustomerData();
      const trekUCompetencyLevel = response?.data?.data?.trekUCompetencyLevel;
      trekUCompetencyLevel
        ? this.updateTrekUUserCompetency(trekUCompetencyLevel)
        : this.updateTrekUUserCompetency(undefined);
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    ...mapMutations('user', ['updateTrekUCompetency']),
    updateTrekUUserCompetency(trekUCompetency) {
      this.updateTrekUCompetency(trekUCompetency);
    },
  },
};
</script>

<style scoped>
::v-deep .pdl-collapse-item__wrap {
  border-bottom: none;
}

::v-deep .pdl-collapse-item__header {
  border-bottom: none;
}

::v-deep .pdl-media-object-list {
  border: none;
}

::v-deep .pdl-media-object-list .pdl-media-object:not(:last-child) {
  border-bottom: none;
}

::v-deep .button + .button {
  margin-top: 0.25rem;
}
</style>
