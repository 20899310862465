<template>
  <div :qaid="`cart-entry-${entryNumber}-fulfillment-option-container`" :class="{flex: !isCheckoutConfirmation}">
    <div class="relative text-sm grow">
      <shipping-option-title
        :shipping-type="deliveryMode"
        level="3"
        :qaid="`cart-entry-${entryNumber}-${entry.deliveryMode.code}-fulfillment-option-icon`"
      >
        <span class="text-body text-md" :qaid="`cart-entry-${entryNumber}-${entry.deliveryMode.code}-fulfillment-name`">
          {{ $t(fulfillmentOptionHeading) }}
        </span>
      </shipping-option-title>
      <p :qaid="`cart-entry-${entryNumber}-arrival-date`">
        {{ availabilityArrivalText }}
      </p>
      <div v-if="deliveryMode === FulfillmentOptionTypes.PICK_UP_IN_STORE">
        <p
          class="pt-1"
          :qaid="`cart-entry-${entryNumber}-store-name`"
          v-html="
            $t('checkout.multi.pickupInStore.atRetailer', [
              `<span class='font-bold'>${retailerForDelivery.displayName}</span>`,
            ])
          "
        />
        <p :qaid="`cart-entry-${entryNumber}-store-address`" class="text-xs">
          {{ retailerForDelivery.address.formattedAddress }}
        </p>
        <p :qaid="`cart-entry-${entryNumber}-store-country`" class="text-xs">
          {{ retailerForDelivery.address.displayCountry }}
        </p>
      </div>
    </div>
    <checkout-edit-link
      v-if="!isCheckoutConfirmation"
      :link-config="linkConfig"
      custom-class="ml-3 whitespace-nowrap"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ShippingOptionTitle from './ShippingOptionTitle.vue';
import {DeliveryModeTypes, DeliveryModeFulfillmentOptionMap} from '@/constants/delivery-modes';
import {FulfillmentOptionHeadings, FulfillmentOptionTypes} from '@/constants/fulfillment-options';
import CheckoutEditLink from '@/components/checkout/CheckoutEditLink.vue';
import {cartActions} from '@/store/modules/cart';
import store from '@/store';
import isEmpty from 'lodash/isEmpty';

export default {
  components: {
    ShippingOptionTitle,
    CheckoutEditLink,
  },
  props: {
    retailerForDelivery: {
      type: Object,
      default: () => ({}),
    },
    entry: {
      type: Object,
      default: () => ({}),
    },
    isCheckoutConfirmation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FulfillmentOptionTypes,
    };
  },
  computed: {
    ...mapState('user', ['selectedRetailer']),
    ...mapState('cart', ['cartFulfillmentOptions', 'cartFulfillmentSpeed']),

    deliveryWindow() {
      const fulfillmentOption = this.cartFulfillmentOptions
        .find((option) => option.orderEntryId === this.entry.entryNumber)
        ?.fulfillmentOptions?.find((option) =>
          option.deliveryModes?.find((dm) => dm.code === this.entry.deliveryMode?.code)
        );
      return fulfillmentOption?.deliveryModes?.find((dm) => dm.code === this.entry.deliveryMode?.code)?.deliveryWindow;
    },
    isBOPISBike() {
      return isEmpty(this.deliveryWindow) && this.entry.deliveryMode.code === DeliveryModeTypes.BOPIS;
    },
    isBOPISAftermarket() {
      return isEmpty(this.deliveryWindow) && this.entry.deliveryMode.code === DeliveryModeTypes.BOPIS_NO_BIKE;
    },
    availabilityArrivalText() {
      if (this.isBOPISBike) {
        return this.entry.messageKey
          ? this.$t(this.entry.messageKey)
          : this.$t('fulfillment.storePickup.availability.48hrs');
      }
      if (this.isBOPISAftermarket) {
        return this.entry.messageKey
          ? this.$t(this.entry.messageKey)
          : this.$t('fulfillment.storePickup.availability.today');
      }
      if (this.entry.promisedShipDate && this.entry.namedDeliveryDate) {
        return this.$t('fulfillment.shipToHome.cart.arrivalDate.range', {
          startDate: this.entry.promisedShipDate,
          endDate: this.entry.namedDeliveryDate,
        });
      }
      if (this.entry.namedDeliveryDate) {
        return this.$t('fulfillment.multi.arrivesBy', [this.entry.namedDeliveryDate]);
      }
      if (this.deliveryWindow) {
        return this.deliveryWindow.start === this.deliveryWindow.end
          ? this.$t('fulfillment.multi.arrivesBy', [this.deliveryWindow.end])
          : this.$t('fulfillment.shipToHome.cart.arrivalDate.range', {
              startDate: this.deliveryWindow.start,
              endDate: this.deliveryWindow.end,
            });
      } else return '';
    },
    entryNumber() {
      return this.entry.entryNumber;
    },
    deliveryMode() {
      return DeliveryModeFulfillmentOptionMap[this.entry?.deliveryMode?.code];
    },
    fulfillmentOptionHeading() {
      return FulfillmentOptionHeadings[this.deliveryMode];
    },
    linkConfig() {
      return {
        id: `cart-entry-edit-link-${this.entry.entryNumber}`,
        href: `/cart/#cart-item-${this.entry.entryNumber}`,
        text: this.$t('checkout.multi.deliveryMethod.editInCart'),
      };
    },
  },

  async mounted() {
    await store.dispatch(cartActions.GET_FULFILLMENT_OPTIONS);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .pdl-card {
  &:last-of-type {
    margin-bottom: 0;
  }
}

::v-deep .pdl-card__body {
  pointer-events: none;
}

::v-deep .pdl-card--padded .pdl-card__body {
  padding: 8px 12px 8px 8px;
}
</style>
