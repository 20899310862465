import ProductLabelYear from '@/components/containers/plp/ProductCard/ProductLabelYear';
import ProductCompareButton from '@/components/compare/ProductCompareButton';
import swatchMixin from '@/mixins/swatches.js';
import {PdlSwatchGroup} from '@pedal/pdl-swatch';
import {ProductImages} from '@/components/containers/pdp/utils/product-images';
import {Url} from '@/utils/url';
import {generateId} from '@/utils/util';
import {mapState, mapGetters} from 'vuex';
import {ConsumerProductPriceData} from '@/components/containers/pdp/utils/consumer-product-price-data';
import {ProductPriceData} from '@/components/containers/pdp/utils/product-price-data';

export default {
  mixins: [swatchMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    isCompareButtonEnabled: {
      type: Boolean,
      default: true,
    },
    isCarousel: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '0',
    },
    sectionType: {
      type: String,
      default: '',
    },
    isProductReferences: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProductLabelYear,
    ProductCompareButton,
    PdlSwatchGroup,
  },

  data() {
    return {
      currentImageUrl: '',
      currentColor: '',
      radioName: `swatch-${generateId()}`,
    };
  },

  computed: {
    ...mapState('plp', ['isExcludingTaxesShown']),
    ...mapState('backend', ['b2b', 'isConsumerFriendlyMode', 'productNoImageUrl']),
    ...mapGetters('advancedOrdering', ['advancedOrderingMode']),
    ...mapGetters('checkout', ['isRestrictedToRetailer']),

    productUrl() {
      if (!this.product.url) {
        return '';
      }

      let url = Url.withContextPath(this.product.url);
      if (this.currentColor && this.currentColor.length > 0) {
        url += `${url.indexOf('?') > -1 ? '&' : '?'}colorCode=${this.currentColor}`;
      }

      return url;
    },

    productUrlCustomizerOpen() {
      if (!this.product.url) {
        return '';
      }

      let url = Url.withContextPath(this.product.url);
      url += `${url.indexOf('?') > -1 ? '&' : '?'}hasSpectrumOpen=true`;

      return url;
    },

    swatches() {
      const swatchArray = this.isProductReferences
        ? this.product.variantAttributesDirectory?.[0]?.options
        : this.product.colorSwatchImageUrl;

      if (!Array.isArray(swatchArray)) {
        return [];
      }

      const colors = swatchArray.map((item, swatchIndex) => {
        let swatchName;
        if (this.isProductReferences) {
          swatchName = item.value.includes('colorswatch') ? item.colorSwatchName : null;
        } else {
          const index = item.indexOf('_url=');
          if (index < 0) {
            return {};
          }
          swatchName = item.substring(0, index);
        }

        return {
          // name is optional for the swatch group component, but required in
          // this context for changeImage(). Name must be unique.
          id: swatchIndex,
          name: swatchName && `${swatchName}-${swatchIndex}`,
          radioName: this.radioName,
          ...this.processColorCode(swatchName),
        };
      });

      // Filter out any empty objects.
      return colors.filter((c) => c.name);
    },

    displayPrice() {
      const priceValue = this.advancedOrderingMode && this.b2b ? 'advancedValue' : 'value';
      return this.price.displayPrice?.[priceValue];
    },

    price() {
      if (this.isProductReferences) {
        const priceType = 'consumerPrice';
        const pricingData = this.product.prices[priceType] ?? {};
        const mappedPricingData = this.mapPricingData(priceType, pricingData);
        const productPriceData = this.getProductPriceData(priceType, mappedPricingData);

        return productPriceData;
      } else {
        return this.getNonProductReferencesPrice();
      }
    },

    isCustomizeVisible() {
      return !!(this.product.projectOneUrl && !this.isRestrictedToRetailer);
    },

    isCompareButtonVisible() {
      return this.isCompareButtonEnabled && !this.product.isArchived;
    },

    isPriceVisible() {
      return this.isCarousel || (!this.product.isArchived && this.product?.price);
    },
    displayName() {
      return this.isProductReferences ? this.product.name : this.product.displayName;
    },
  },

  mounted() {
    const primaryColor = this.swatches[0];
    this.changeImage(primaryColor);
  },

  methods: {
    changeImage(color) {
      if (color?.name) {
        const removeIndex = color.name.lastIndexOf('-');
        this.currentColor = color.name.substring(0, removeIndex);
      }
      this.setImage(color?.id);
    },
    setImage(colorId) {
      if (this.isProductReferences) {
        const imageKey = this.currentColor || Object.keys(this.product.images)[0];
        const asset = this.product.images[imageKey][0];
        this.currentImageUrl = ProductImages.getAssetUrl(asset.assetId, {w: 480, h: 360});
      } else {
        if (this.product?.colorSwatchImageUrl?.length && colorId !== null && colorId !== undefined) {
          const currentImage = this.product.colorSwatchImageUrl.find((item, i) => colorId === i);
          this.currentImageUrl = currentImage.split('url=')[1];
        } else {
          if (this.product?.images?.length > 0) {
            this.currentImageUrl = this.product.images[0]?.url;
          } else if (this.product?.imageURLList?.length > 0) {
            this.currentImageUrl = this.product.imageURLList[0];
          } else {
            this.currentImageUrl = this.productNoImageUrl;
          }
        }
      }
    },

    mapPricingData(priceType, pricingData) {
      const mappedPricingData = {...pricingData};

      if (priceType === 'consumerPrice') {
        const hasPricingData = pricingData && Object.keys(pricingData).length > 0;
        const pricingKeys = hasPricingData || this.isConsumerFriendlyMode ? ['apple'] : [];

        pricingKeys.forEach((key) => {
          const itemData = this.product?.prices?.[priceType]?.[key];
          if (itemData) {
            mappedPricingData[key] = itemData;
          }
        });
      } else {
        const pricingKeys = ['advertised', 'saleAdvertised'];

        pricingKeys.forEach((key) => {
          const itemData = this.product?.prices?.[priceType]?.[key];
          if (itemData) {
            mappedPricingData[key] = itemData;
          }
        });
      }

      return mappedPricingData;
    },

    getProductPriceData(priceType, mappedPricingData) {
      const isConsumerPriceType = priceType === 'consumerPrice' || this.isConsumerFriendlyMode;
      if (isConsumerPriceType) {
        return new ConsumerProductPriceData(mappedPricingData, {b2b: this.b2b});
      } else {
        return new ProductPriceData(mappedPricingData, {b2b: this.b2b});
      }
    },

    getNonProductReferencesPrice() {
      const excludingTaxesText = this.isExcludingTaxesShown ? this.$t('product.price.excludeTax') : '';
      const result = {
        sale: `${this.product.productPriceRange} ${excludingTaxesText}`,
        adv: '',
      };

      if (this.product.wasPriceRange && this.product.wasPriceRange !== this.product.productPriceRange) {
        result.adv = `${this.product.wasPriceRange} ${excludingTaxesText}`;
      }

      return result;
    },
  },
};
