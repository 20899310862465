import {Cloudinary, CloudinaryConstants} from '@/utils/image/cloudinary-helpers';

const thumbnailSize = {
  width: 160,
  height: 120,
};

const srcSetSizes = [
  {width: 480, height: 360},
  {width: 640, height: 480},
  {width: 768, height: 576},
  {width: 1024, height: 768},
  {width: 1200, height: 900},
  {width: 1440, height: 1080},
  {width: 1920, height: 1440},
];

const ProductImages = {
  /**
   * Get array of sizes for the given asset.
   * @param assetId
   * @param urlParams
   * @return {Array}
   */
  getSrcSet(assetId, urlParams = {}) {
    let srcset = [];
    srcSetSizes.forEach((size) => {
      const url = this.getAssetUrl(assetId, {w: size.width, h: size.height, ...urlParams});
      srcset.push(`${url} ${size.width}w`);
    });
    return srcset;
  },

  /**
   * Get formatted thumbnail URL.
   * @param assetId
   * @param urlParams
   * @return {*|string}
   */
  getThumbnailUrl(assetId, urlParams = {}) {
    return this.getAssetUrl(assetId, {
      w: thumbnailSize.width,
      h: thumbnailSize.height,
      ...urlParams,
    });
  },

  /**
   * Get asset's URL for given asset ID and url parameters.
   * @param assetId
   * @param urlParams
   * @return {string}
   */
  getAssetUrl(assetId, urlParams = {}) {
    const parameters = {
      ...CloudinaryConstants.DEFAULT_PARAMETERS,
      ...urlParams,
    };
    return Cloudinary.createAssetUrl(assetId, parameters);
  },

  getAltTextTranslation(assetId) {
    if (!assetId) return;
    return assetId.replace('_', '-');
  },
};

export {ProductImages};
