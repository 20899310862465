'use strict';

export default function () {
  $('body').magnificPopup({
    disableOn: 300,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
    delegate: '[data-video]',
    iframe: {
      patterns: {
        youtube: {
          src: '//www.youtube.com/embed/%id%', // URL that will be set as a source for iframe.
        },
      },
    },
  });
}
