/**
 * This mixin includes methods used in color swatch implementation.
 */

export default {
  methods: {
    // Convert the common underscore-separated color codes to an object of color names.
    processColorCode(string) {
      if (!string) {
        return {};
      }

      return {
        firstColor: this.getColorFromCode(string, 0),
        secondColor: this.getColorFromCode(string, 1),
      };
    },
    // Get a single color code part. Used for one-off swatch cases.
    getColorFromCode(color, index) {
      const colors = color.toLowerCase().split('_');

      return colors[index] || null;
    },
  },
};
