<template>
  <div v-if="retailerForDelivery">
    <pdl-label v-if="hasTitle" :qaid="`${qaid}-title`" class="mb-1">{{ $t('checkout.summary.retailer') }}</pdl-label>
    <p v-if="isClickCollect" :qaid="`${qaid}-delivered-message`" class="mb-2">
      {{ $t('checkout.summary.order.deliverted.to') }}:
    </p>
    <p :qaid="`${qaid}-name`">{{ retailerForDelivery.description }}</p>
    <address-summary :address="retailerForDelivery.address" :qaid="qaid" />

    <checkout-edit-link v-if="!isRestrictedToRetailer" :qaid="qaid" :link-config="linkConfig" />
  </div>
</template>

<script>
import {PdlLabel} from '@pedal/pdl-label';
import AddressSummary from '@/components/AddressSummary.vue';
import CheckoutEditLink from '@/components/checkout/CheckoutEditLink.vue';
import {mapGetters} from 'vuex';

export default {
  name: 'RetailerDetails',
  components: {AddressSummary, PdlLabel, CheckoutEditLink},
  props: {
    retailerForDelivery: {
      type: Object,
      default: () => null,
    },
    isClickCollect: {
      type: Boolean,
      default: false,
    },
    linkConfig: {
      type: Object,
      default: () => null,
    },
    qaid: {
      type: String,
      default: '',
    },
    hasTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('checkout', ['isRestrictedToRetailer']),
  },
};
</script>
