const ImageBreakpointMap = Object.freeze({
  XS_WIDTH: 'xsWidth',
  SM_WIDTH: 'smWidth',
  MD_WIDTH: 'mdWidth',
  ML_WIDTH: 'mlWidth',
  LG_WIDTH: 'lgWidth',
  XL_WIDTH: 'xlWidth',
  XXL_WIDTH: 'xxlWidth',
});

export default ImageBreakpointMap;
