<template>
  <fieldset v-if="shippingOptionsList" class="border-b border-t border-solid border-gray-10 mb-5 pt-4">
    <div class="mb-5">
      <legend class="mb-2 header-xsmall">3. {{ $t('text.shippingOptions') }}</legend>
      <template v-for="option in shippingOptionsList.options">
        <label :key="option.code" class="radio" :qaid="`${option.code}-label`">
          <input
            v-model="selectedOption"
            type="radio"
            :qaid="`${option.code}-radio-button`"
            :value="option.code"
            @change="updateSelectedOption"
          />
          <span class="control-indicator"></span>
          <div>
            {{ option.name }}
            <p
              v-if="isGreenShippingMessage && option.code === 'enumGREEN'"
              class="mb-1 form-help"
              :qaid="`${option.code}-restricted-message`"
            >
              {{ $t('checkout.B2B.greenShipping.message') }}
            </p>
          </div>
        </label>
      </template>
    </div>
  </fieldset>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex';

export default {
  name: 'TrekUShippingOptions',
  inject: ['cartDataFull', 'shipmentInfos'],
  data() {
    return {
      selectedOption: '',
    };
  },

  computed: {
    ...mapState('shipments', ['shipmentsInfo']),

    shippingOptionsList() {
      let shippingOptions = {};
      for (const data in this.shipmentsInfo?.shipments) {
        shippingOptions = this.shipmentsInfo.shipments[data].shippingOptions;
      }

      return shippingOptions;
    },

    isGreenShippingMessage() {
      return this.cartDataFull.hasBike && this.cartDataFull.hasAfterMarket && this.selectedOption === 'enumGREEN';
    },
  },

  created() {
    this.loadShipmentsOptions();
    this.loadProductData();
  },

  methods: {
    ...mapActions('shipments', ['fetchCart', 'fetchTotalShippingCharge']),
    ...mapMutations('shipments', ['updateShipmentInfo', 'setShipmentsInfo']),

    async loadShipmentsOptions() {
      this.setShipmentsInfo(this.shipmentInfos);
      this.selectedOption = this.shippingOptionsList.selected;
      this.updateShipmentInfo(this.selectedOption);
    },

    async loadProductData() {
      await this.fetchCart();
    },

    updateSelectedOption(event) {
      this.selectedOption = event.target.value;
      this.updateShipmentInfo(event.target.value);
      this.fetchTotalShippingCharge();
    },
  },
};
</script>
