<template>
  <div class="checkout-confirmation-container vertical-spacer-4">
    <div class="row mb-6">
      <pdl-heading qaid="order-confirmation-header" class="mb-3" :level="2" size="xl">{{
        $t('checkout.order.confirmation')
      }}</pdl-heading>
      <pdl-heading :level="3" size="lg">{{ $t('checkout.confirmation.thankyou') }}</pdl-heading>
      <section class="mb-3" qaid="order-confirmation-code">
        <span>{{ $t('checkout.confirmation.orderid') }}&nbsp;{{ orderData.code }}</span>
      </section>
      <section class="mb-3">
        <p class="mb-1">
          {{ $t('checkout.email.confirmation') }}&nbsp;<strong>{{ emailAddress }}</strong>
        </p>
        <p>{{ $t('checkout.confirmation.message') }}</p>
      </section>
      <template v-if="calloutConfig">
        <pdl-callout :kind="calloutConfig.kind">
          <template #content>
            {{ calloutConfig.message }}
          </template>
        </pdl-callout>
      </template>
      <template v-if="hasUpdateRetailerOption">
        <p class="mb-1">{{ $t('checkout.confirmation.favourite.retailer') }}</p>
        <retailer-details
          class="address-and-billing-details__item text-strong"
          :retailer-for-delivery="orderData.retailerForDelivery"
          :has-title="false"
          qaid="update-retailer"
        />
        <div class="buttons">
          <pdl-button id="update-retailer-button" primary @click="updateRetailer">
            <span>{{ $t('checkout.confirmation.update.retailer') }}</span>
          </pdl-button>
          <pdl-link id="checkout-confirm-no-thanks-link" button secondary :href="`${encodedContextPath}`">
            <span>{{ $t('checkout.confirmation.no.thanks') }}</span>
          </pdl-link>
        </div>
      </template>
      <guest-register
        v-else-if="!isUserLoggedIn && isGuestRegisterFormVisible"
        :order-id="orderData.guid"
        :email-address="emailAddress"
        :has-electra-optin-toggle="hasElectraOptinToggle"
        @guestRegistered="handleGuestRegistration"
      />
    </div>
    <div class="grid md:grid-cols-2 lg:grid-cols-12 gap-x-8 lg:gap-x-6 row">
      <section class="columns lg:col-span-8 md:order-none lg:pr-2">
        <pdl-heading :level="2" size="md" class="mb-3 md:mb-5">
          {{ $t('checkout.confirmation.delivery.summary') }}
        </pdl-heading>
        <section class="mb-4">
          <div class="mb-2">
            <pdl-heading :level="3" size="sm">{{ $t('checkout.summary.ordersummary') }}</pdl-heading>
          </div>
          <div class="grid md:grid-cols-2 gap-x-8 gap-y-8">
            <delivery-details
              :address="orderData.deliveryAddress"
              :email-address="emailAddress"
              qaid="delivery-summary"
            />
            <payment-method :payment-info="orderData.paymentInfo" is-showing-payment-method qaid="billing-details" />
          </div>
        </section>
        <checkout-summary-list
          :cart-data="orderData"
          :cart-entries="cartData[0].entries"
          :retailer-for-delivery="orderData.retailerForDelivery"
          is-checkout-confirmation
        />
      </section>
      <section class="columns mt-6 lg:col-span-4 order-none">
        <order-summary-aside class="mb-4" hide-product-list-and-buttons :cart-data="orderData" />
        <payment-icon-list v-if="!isRestrictedToRetailer" />
      </section>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState, mapGetters} from 'vuex';
import CheckoutSummaryList from '@/components/cart/b2c/CheckoutSummaryList.vue';
import GuestRegister from '@/components/checkout/GuestRegister.vue';
import {PdlHeading} from '@pedal/pdl-section-header';
import {PdlCallout} from '@pedal/pdl-callout';
import {PdlButton} from '@pedal/pdl-button';
import {PdlLink} from '@pedal/pdl-link';
import DeliveryDetails from '@/components/checkout/DeliveryDetails.vue';
import PaymentMethod from '@/components/checkout/PaymentMethod.vue';
import OrderSummaryAside from '@/components/checkout/order-summary-aside/OrderSummaryAside.vue';
import {UserApi} from '@/api/user.js';
import trackCheckout from '@/mixins/tracking/track-checkout.js';
import PaymentIconList from '@/components/PaymentIconList.vue';

const GUEST_REGISTRATION_ERROR_CODE = 400;
const UPDATED_RETAILER_CALLOUT_KEY = 'hasUpdatedRetailer';

export default {
  name: 'CheckoutConfirmationContainer',
  components: {
    CheckoutSummaryList,
    GuestRegister,
    PdlHeading,
    DeliveryDetails,
    PaymentMethod,
    OrderSummaryAside,
    PdlCallout,
    PdlButton,
    PdlLink,
    PaymentIconList,
  },

  mixins: [trackCheckout],

  props: {
    cartData: {
      type: Array,
      default: () => null,
    },
    orderData: {
      type: Object,
      default: () => ({}),
    },
    emailAddress: {
      type: String,
      default: '',
    },
    isClickCollect: {
      type: Boolean,
      default: false,
    },
    hasElectraOptinToggle: {
      type: Boolean,
      default: false,
    },
    hasChangedRetailer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasUpdatedRetailer: false,
      isGuestRegisterFormVisible: true,
      calloutConfig: null,
    };
  },
  computed: {
    ...mapState('backend', ['encodedContextPath', 'isUserLoggedIn']),
    ...mapGetters('checkout', ['isRestrictedToRetailer']),

    hasUpdateRetailerOption() {
      return this.isUserLoggedIn && this.hasChangedRetailer && !this.hasUpdatedRetailer;
    },
    checkoutConfirmationCalloutConfigMap() {
      return {
        200: {
          kind: 'success',
          message: this.$t('account.create.confirm'),
        },
        400: {
          kind: 'error',
          message: this.$t('errorMessage.unableToProcess'),
        },
        409: {
          kind: 'error',
          message: this.$t('registration.error.account.exists.title'),
        },
        hasUpdatedRetailer: {
          kind: 'success',
          message: this.$t('appleLabel.changesSaved'),
        },
      };
    },
  },
  created() {
    // Sets the entries to the checkout store for Multi steps
    this.setupCartData();
  },
  methods: {
    ...mapMutations('checkout', ['setB2cCartData']),

    handleGuestRegistration(responseCode) {
      this.calloutConfig = this.checkoutConfirmationCalloutConfigMap[responseCode];
      if (responseCode !== GUEST_REGISTRATION_ERROR_CODE) {
        this.isGuestRegisterFormVisible = false;
      }
    },

    /**
     * Handles setting the entries into the checkout store.
     */
    setupCartData() {
      if (this.cartData === null) return;

      this.setB2cCartData(this.cartData);
    },
    updateRetailer() {
      UserApi.updatePreferredRetailer(this.orderData.retailerForDelivery.name, this.orderData.code)
        .then(() => {
          this.calloutConfig = this.checkoutConfirmationCalloutConfigMap[UPDATED_RETAILER_CALLOUT_KEY];
          this.hasUpdatedRetailer = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-confirmation-container {
  margin-bottom: 7.5rem;
}

.address-and-billing-details__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
</style>
