<template>
  <div class="space-y-1" qaid="store-pickup-fulfillment-block">
    <div class="flex gap-x-1 items-center">
      <pick-up-in-store-icon />
      <span qaid="store-pickup-header" class="text-md font-bold">{{ $t(option.name) }}</span>
    </div>
    <div v-if="hasMessaging" class="leading-normal text-sm">
      <span v-if="baseMessage && baseMessage === soldOutMessage" v-html="$t(baseMessage)" />
      <i18n v-else-if="baseMessage && baseMessage === notAvailableMessage" :path="baseMessage" tag="p">
        <template>
          <pdl-button small underline text qaid="select-retailer-button" @click="setIsDrawerOpen(true)">
            {{ $t(viewOtherStoresMessage) }}
          </pdl-button>
        </template>
      </i18n>
      <i18n
        v-else-if="baseMessage && isStorePickupSoonMessage"
        qaid="store-pickup-soon-content"
        :path="baseMessage"
        tag="p"
      >
        <template #retailer>
          <pdl-button small underline text qaid="select-retailer-button" @click="setIsDrawerOpen(true)">
            {{ selectedRetailer.displayName }}
          </pdl-button>
        </template>
      </i18n>
      <i18n v-else-if="baseMessage" qaid="store-pickup-content" :path="baseMessage" tag="p">
        <template #retailer>
          <pdl-button small underline text qaid="select-retailer-button" @click="setIsDrawerOpen(true)">
            {{ selectedRetailer.displayName }}
          </pdl-button>
        </template>
        <template #startDate>
          <span class="font-bold">{{ startDate }}</span>
        </template>
        <template #endDate>
          <span class="font-bold">{{ endDate }}</span>
        </template>
      </i18n>
      <p v-if="buildTimeMessage" class="mt-1" qaid="store-pickup-locally-message">{{ $t(buildTimeMessage) }}</p>
      <div v-if="dealerDeliveryMessage && !hasRichMessaging" class="flex gap-x-2">{{ $t(dealerDeliveryMessage) }}</div>
      <p v-if="locallyMessage" qaid="store-pickup-locally-message">{{ $t(locallyMessage) }}</p>
      <div v-if="retailersNearYou" class="flex gap-x-1 mt-2">
        <div class="flex items-center gap-x-1 text-green-140">
          <pdl-icon name="check_circle" size="18" />
          <i18n :path="locallyStoresNearMessage" tag="span" qaid="store-pickup-near-stores-message">
            <template>
              <span>{{ retailersNearYou }}</span>
            </template>
          </i18n>
        </div>
        <pdl-button small underline text qaid="store-pickup-find-stores-button" @click="setIsDrawerOpen(true)">
          {{ $t('pickup.search.button') }}
        </pdl-button>
      </div>
    </div>
    <pdl-button v-else small underline text qaid="select-retailer-button" @click="setIsDrawerOpen(true)">
      {{ $t('checkout.multi.retailer.error') }}
    </pdl-button>
  </div>
</template>

<script>
import {PdlIcon} from '@pedal/pdl-icon';
import {PdlButton} from '@pedal/pdl-button';
import PickUpInStoreIcon from '@/components/containers/pdp/b2c/fulfillment-options/store-pick-up/PickUpInStoreIcon.vue';
import {mapState} from 'vuex';
import {useFulfillmentOptionMessaging} from '@/composables/fulfillment-option-messaging.js';
import {toRefs} from 'vue';

const NOT_AVAILABLE_BP_KEY = 'fulfillment.storePickup.selectedRetailer.notAvailable';
const SOLD_OUT_BP_KEY = 'fulfillment.storePickup.selectedRetailer.soldOut';
const VIEW_STORES_BP_KEY = 'fulfillment.storePickup.selectedRetailer.viewOtherStores';
const STORE_PICKUP_TODAY_BP_KEY = 'fulfillment.storePickup.availability.today.atRetailer';
const STORE_PICKUP_48HRS_BP_KEY = 'fulfillment.storePickup.availability.48hrs.atRetailer';
const STORES_NEAR_YOU = 'fulfillment.storePickup.nearStore.message';

export default {
  components: {PdlButton, PdlIcon, PickUpInStoreIcon},
  props: {
    setIsDrawerOpen: {
      type: Function,
      default: () => {},
    },
    option: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const {option} = toRefs(props);
    const {
      hasRichMessaging,
      hasMessaging,
      startDate,
      endDate,
      baseMessage,
      locallyMessage,
      buildTimeMessage,
      dealerDeliveryMessage,
    } = useFulfillmentOptionMessaging(option);

    return {
      hasRichMessaging,
      hasMessaging,
      startDate,
      endDate,
      baseMessage,
      locallyMessage,
      buildTimeMessage,
      dealerDeliveryMessage,
    };
  },
  data() {
    return {
      notAvailableMessage: NOT_AVAILABLE_BP_KEY,
      soldOutMessage: SOLD_OUT_BP_KEY,
      viewOtherStoresMessage: VIEW_STORES_BP_KEY,
      locallyStoresNearMessage: STORES_NEAR_YOU,
    };
  },
  computed: {
    ...mapState('user', ['selectedRetailer']),
    ...mapState('pdp', ['isLocallyActive', 'locallyStockAvailableRetailers']),

    isStorePickupSoonMessage() {
      return this.baseMessage === STORE_PICKUP_TODAY_BP_KEY || this.baseMessage === STORE_PICKUP_48HRS_BP_KEY;
    },
    retailersNearYou() {
      return this.locallyStockAvailableRetailers.some((retailer) => retailer.name === this.selectedRetailer.name)
        ? this.locallyStockAvailableRetailers.length - 1
        : this.locallyStockAvailableRetailers.length;
    },
  },
};
</script>
