<template>
  <div v-if="!product.isArchived" class="b2b-buying-zone__price flex flex-wrap items-center heading--sm font-medium">
    <span v-if="price" id="priceRange">
      <input id="product-display-price" type="hidden" :value="displayPrice" />
      <span qaid="actual-price" class="actual-price" :class="{salePrice: price.displayPrice.isSale}">{{
        displayPrice
      }}</span>
      <span class="sr-only">{{ $t('text.buyingZone.salePrice') }}</span>
      <span v-if="price.excludeTaxes && isConsumerFriendlyMode" id="excludeTaxText" class="exclude-tax">
        {{ $t('product.price.excludeTax') }}
      </span>
    </span>

    <span v-if="price && price.displayPrice.oldPrice">
      <span qaid="original-price" class="text-gray-60 line-through ml-1">
        {{ price.displayPrice.oldPrice }}
      </span>
      <span class="sr-only">{{ $t('text.buyingZone.originalPrice') }}</span>
    </span>
    <span v-if="b2b" class="ml-1 text-sm">
      <span v-if="isConsumerFriendlyMode" :qaid="qaidValueRetail">{{ $t('text.suggestedRetail') }}</span>
      <span v-else :qaid="qaidValueRetail">{{ $t('text.retailerCost') }}</span>
    </span>

    <pdp-product-price-tooltip
      v-if="b2b"
      v-show="!isConsumerFriendlyMode"
      :price="price"
      @min-qty-change="updateMinQty"
    >
    </pdp-product-price-tooltip>
    <template v-if="isRestrictedToRetailer">
      <pdl-label v-if="trekUCompetencyLevel" qaid="pdp-treku-competency-label" class="pl-5" kind="positive">{{
        trekUCompetencyLevelTruncated
      }}</pdl-label>
      <pdl-label v-else class="pl-5" qaid="pdp-treku-competency-label" kind="important">{{
        $t('text.buyingZone.B2B.trekU.noDiscount')
      }}</pdl-label>
    </template>
  </div>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
import get from 'lodash/get';
import PdpProductPriceTooltip from '@/components/containers/pdp/b2b/PdpProductPriceTooltip';
import {PdlLabel} from '@pedal/pdl-label';

export default {
  name: 'PdpProductPrice',
  components: {PdpProductPriceTooltip, PdlLabel},
  props: {
    product: {
      type: Object,
      required: true,
    },
    retailer: {
      type: Object,
      default: () => null,
    },
    consumer: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapState('backend', ['isConsumerFriendlyMode', 'b2b']),
    ...mapState('user', ['trekUCompetencyLevel']),
    ...mapGetters('advancedOrdering', ['advancedOrderingMode']),
    ...mapGetters('checkout', ['isRestrictedToRetailer']),

    displayPrice() {
      return get(this.price.displayPrice, this.advancedOrderingMode && this.b2b ? 'advancedValue' : 'value', '');
    },
    price() {
      if (!this.b2b) {
        return this.consumer;
      }
      return this.isConsumerFriendlyMode ? this.consumer : this.retailer;
    },
    qaidValueRetail() {
      return !this.isConsumerFriendlyMode ? 'retail.cost.text' : 'retail.suggested.text';
    },
    trekUCompetencyLevelTruncated() {
      return this.trekUCompetencyLevel.split(' ')[0];
    },
  },
  methods: {
    updateMinQty(quantity) {
      this.$emit('min-qty-change', quantity);
    },
  },
};
</script>
