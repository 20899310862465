<template>
  <form-group
    v-model="firstName"
    :maxlength="19"
    :name="fieldName"
    :label="$t('address.firstName')"
    required
    show-error-messages
    force-display-error
    :error="error ? error : getErrorMessage('firstName')"
    qaid="first-name-field"
  />
</template>

<script>
import FormGroup from '@/components/FormGroup';
import {mapState, mapMutations} from 'vuex';
import {TrekValidationMixin} from '@/utils/validation/trek-validation-mixin';

export default {
  components: {FormGroup},
  mixins: [TrekValidationMixin],
  props: {
    error: {
      type: String,
      default: undefined,
    },
  },

  validations() {
    return {
      firstName: {
        required: this.trekValidators.requiredWithCustomMessage('address.firstName.invalid'),
        maxLength: this.trekValidators.maxLength('address.firstName', 19),
      },
    };
  },
  computed: {
    ...mapState('checkout', ['billingAddress']),
    fieldName() {
      return 'firstName';
    },
    firstName: {
      get() {
        return this.billingAddress.firstName;
      },
      set(firstName) {
        this.setFirstName(firstName);
      },
    },
  },
  watch: {
    firstName(inputValue) {
      this.$emit('input', {field: `${this.fieldName}Value`, value: inputValue});
    },
  },

  methods: {
    ...mapMutations('checkout', ['setFirstName']),
  },
};
</script>
